<template>
  <div
    class="modal fade"
    :id="`modalFormulaEditor`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalFormulaEditorLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h5 class="modal-title" :id="`modalFormulaEditorLabel`">
            <img :src="require('@/assets/images/icons/function-primary.png')" />
            {{
              $t(
                "Components.FormulaEditorModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <div
                class="col formula-editor-formula-field-info"
                v-if="
                  formulaField &&
                  !String.isNullOrWhiteSpace(formulaField.formulaName)
                "
              >
                <div class="formula-editor-formula-field mt-0 mb-2 p-2">
                  <div
                    class="row d-flex justify-content-center align-items-center"
                  >
                    <div class="col">
                      <h3 class="text-center text-primary">
                        <template
                          v-if="
                            formulaField.isFirstField ||
                            formulaField.formulaName === 'CREATEDBY' ||
                            formulaField.formulaName === 'UPDATEDBY' ||
                            formulaField.formulaName === 'CREATEDAT' ||
                            formulaField.formulaName === 'UPDATEDAT'
                          "
                        >
                          <i
                            class="bi bi-tag-fill fs-4"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              this.$t(
                                'BaseModelFields.CurrentFieldSystemField',
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            "
                          ></i>
                          <a
                            :href="
                              String.format(
                                '#/CustomObject/EditField/{0}&fieldId={1}',
                                formulaField.customObjectPublicId,
                                formulaField.publicId
                              )
                            "
                            target="_blank"
                          >
                            {{ formulaField.name }}
                          </a>
                        </template>
                        <template v-else>
                          <a
                            :href="
                              String.format(
                                '#/CustomObject/EditField/{0}&fieldId={1}',
                                formulaField.customObjectPublicId,
                                formulaField.publicId
                              )
                            "
                            target="_blank"
                          >
                            {{ formulaField.name }}
                          </a>
                        </template>
                      </h3>
                    </div>
                    <div class="col">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered mb-0">
                          <thead class="table-primary">
                            <tr>
                              <th class="text-center text-capitalize">
                                {{
                                  $t(
                                    "Fields.FormulaName",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}
                              </th>
                              <th class="text-center text-capitalize">
                                {{
                                  $t(
                                    "Fields.Type",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}
                              </th>
                              <th class="text-center text-capitalize">
                                {{
                                  $t(
                                    "Fields.IsRequired",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}
                              </th>
                              <th class="text-center text-capitalize">
                                {{
                                  $t(
                                    "Fields.IsUnique",
                                    {},
                                    { locale: this.$store.state.activeLang }
                                  )
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center">
                                {{ formulaField.formulaName }}
                              </td>
                              <td class="text-center">
                                {{ formulaField.fieldType }}
                              </td>
                              <td class="text-center">
                                <u
                                  class="text-success"
                                  v-if="formulaField.isRequired"
                                >
                                  {{
                                    $t(
                                      "Components.FormulaEditorModal.Yes",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}</u
                                >
                                <u class="text-danger" v-else>
                                  {{
                                    $t(
                                      "Components.FormulaEditorModal.No",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </u>
                              </td>
                              <td class="text-center">
                                <u
                                  class="text-success"
                                  v-if="formulaField.isUnique"
                                >
                                  {{
                                    $t(
                                      "Components.FormulaEditorModal.Yes",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}</u
                                >
                                <u class="text-danger" v-else>
                                  {{
                                    $t(
                                      "Components.FormulaEditorModal.No",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}
                                </u>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="accordion"
                :class="{
                  'mt-3':
                    formulaField &&
                    !String.isNullOrWhiteSpace(formulaField.formulaName),
                }"
                id="accordionFormulaEditor"
              >
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#formulaEditorFields"
                      aria-expanded="false"
                    >
                      <i class="bi bi-card-list"></i>
                      {{
                        $t(
                          "Components.FormulaEditorModal.Fields",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </h2>
                  <div
                    id="formulaEditorFields"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFormulaEditor"
                  >
                    <div
                      class="accordion-body p-2 overflow-auto"
                      style="max-height: 400px"
                    >
                      <input
                        type="text"
                        class="form-control mb-1"
                        @keyup="searchField"
                        v-model="fieldQuery"
                        :placeholder="
                          $t(
                            'Components.FormulaEditorModal.SearchField',
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        "
                      />
                      <div
                        class="accordion"
                        :id="
                          String.format('accordionFormulaEditorOrgUnit_{0}', i)
                        "
                        v-for="(item, i) in orgUnitFormulas"
                        :key="i"
                      >
                        <div class="accordion-item">
                          <h2 class="accordion-header accordion-header-sticky">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="
                                String.format('#orgUnitFields_{0}', i)
                              "
                              aria-expanded="false"
                            >
                              <i class="bi bi-person-circle"></i>
                              {{ item.formulaName }}
                            </button>
                          </h2>
                          <div
                            :id="String.format('orgUnitFields_{0}', i)"
                            class="accordion-collapse collapse"
                            :data-bs-parent="
                              String.format(
                                '#accordionFormulaEditorOrgUnit_{0}',
                                i
                              )
                            "
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="item.formulas"
                                item-key="Id"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="element.Meta"
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-field-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="accordion"
                        :id="
                          String.format('accordionFormulaEditorLookup_{0}', i)
                        "
                        v-for="(item, i) in lookupFormulas"
                        :key="i"
                      >
                        <div class="accordion-item">
                          <h2 class="accordion-header accordion-header-sticky">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="
                                String.format('#lookupFields_{0}', i)
                              "
                              aria-expanded="false"
                            >
                              <i class="bi bi-arrow-left-right"></i>
                              {{ item.formulaName }}
                            </button>
                          </h2>
                          <div
                            :id="String.format('lookupFields_{0}', i)"
                            class="accordion-collapse collapse"
                            :data-bs-parent="
                              String.format(
                                '#accordionFormulaEditorLookup_{0}',
                                i
                              )
                            "
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="item.formulas"
                                item-key="Id"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="element.Meta"
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-field-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                      </div>
                      <draggable
                        :list="fieldFormulas"
                        item-key="Id"
                        ghost-class="ghost"
                        v-bind="{
                          group: 'element-group',
                          sort: false,
                          disabled: false,
                          animation: 300,
                        }"
                      >
                        <template #item="{ element }">
                          <button
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="element.Meta"
                            type="button"
                            class="btn btn-sm btn-secondary w-100 text-start formula-field-button"
                          >
                            {{ element.Name }}
                          </button>
                        </template>
                      </draggable>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#formulaEditorFunctions"
                      aria-expanded="false"
                    >
                      <i class="bi bi-plus-slash-minus"></i>
                      {{
                        $t(
                          "Components.FormulaEditorModal.Functions",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </h2>
                  <div
                    id="formulaEditorFunctions"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFormulaEditor"
                  >
                    <div class="accordion-body p-2">
                      <input
                        type="text"
                        class="form-control mb-1"
                        @keyup="searchFunction"
                        v-model="functionQuery"
                        :placeholder="
                          $t(
                            'Components.FormulaEditorModal.SearchFunction',
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        "
                      />
                      <div class="accordion" id="formulaEditorChildFunctions">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#datetimeFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-clock-fill"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.DateTime",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="datetimeFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="datetimeFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <div
                          class="accordion-item"
                          v-if="logicalFunctions.length > 0"
                        >
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#logicalFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-gear-fill"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.Logical",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="logicalFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="logicalFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#customFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-arrow-left-right"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.Custom",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="customFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="customFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#mathFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-calculator"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.Math",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="mathFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="mathFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#stringFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-type"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.String",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="stringFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="stringFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#spatialFunctions"
                              aria-expanded="false"
                            >
                              <i class="bi bi-geo-alt"></i>
                              {{
                                $t(
                                  "Components.FormulaEditorModal.Spatial",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </button>
                          </h2>
                          <div
                            id="spatialFunctions"
                            class="accordion-collapse collapse"
                            data-bs-parent="#formulaEditorChildFunctions"
                          >
                            <div class="accordion-body p-2">
                              <draggable
                                :list="spatialFunctions"
                                item-key="Name"
                                ghost-class="ghost"
                                v-bind="{
                                  group: 'element-group',
                                  sort: false,
                                  disabled: false,
                                  animation: 300,
                                }"
                              >
                                <template #item="{ element }">
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-secondary w-100 text-start formula-function-button"
                                  >
                                    {{ element.Name }}
                                  </button>
                                </template>
                              </draggable>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#formulaEditorOperators"
                      aria-expanded="false"
                    >
                      <i class="bi bi-braces"></i>
                      {{
                        $t(
                          "Components.FormulaEditorModal.Operators",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </h2>
                  <div
                    id="formulaEditorOperators"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFormulaEditor"
                  >
                    <div class="accordion-body p-2">
                      <input
                        type="text"
                        class="form-control mb-1"
                        @keyup="searchOperator"
                        v-model="operatorQuery"
                        :placeholder="
                          $t(
                            'Components.FormulaEditorModal.SearchOperator',
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        "
                      />
                      <draggable
                        :list="operators"
                        item-key="Name"
                        ghost-class="ghost"
                        v-bind="{
                          group: 'element-group',
                          sort: false,
                          disabled: false,
                          animation: 300,
                        }"
                      >
                        <template #item="{ element }">
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary w-100 text-start formula-operator-button"
                          >
                            {{ element.Name }}
                          </button>
                        </template>
                      </draggable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="col">
                <v-ace-editor
                  v-model:value="formula"
                  lang="csharp"
                  :theme="theme"
                  @init="editorInit"
                  :options="options"
                  id="formulaEditor"
                  style="height: 650px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer p-2">
          <div class="col">
            <div class="colors">
              <a
                v-for="(item, i) in themes.filter((f) => f.id !== theme)"
                :key="i"
                :class="item.bgClassName"
                class="border border-dark"
                @click="setTheme(item.id)"
              >
              </a>
              <span
                class="formula-editor-themes-title"
                v-html="
                  $t(
                    'Components.FormulaEditorModal.ActiveThemeFormat',
                    {},
                    { locale: this.$store.state.activeLang }
                  ).replaceAll(
                    'THEME_ID',
                    themes.find((f) => f.id == theme).bgClassName
                  )
                "
              >
              </span>
            </div>
          </div>
          <button
            @click="updateFormula"
            type="button"
            class="btn btn-success me-2"
          >
            <span>
              <i class="bi bi-save"></i>
              {{
                $t("Buttons.Save", {}, { locale: this.$store.state.activeLang })
              }}</span
            >
          </button>
          <button
            type="button"
            class="btn btn-danger btn-formula-editor-close"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.FormulaEditorModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

import $ from "jquery";

import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-ambiance";
import "ace-builds/src-noconflict/theme-mono_industrial";

import "ace-builds/src-noconflict/theme-idle_fingers";

import "ace-builds/src-noconflict/mode-csharp";

import "ace-builds/src-noconflict/ext-language_tools";
import beautifier from "ace-builds/src-noconflict/ext-beautify";

import "ace-builds/src-noconflict/snippets/csharp";

import "ace-builds/src-noconflict/ext-searchbox";

import ace from "ace-builds";
export default {
  name: "FormulaEditorModal",
  props: {
    formulaField: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    parentRecordPrefix: { type: String, default: "CURRENT_RECORD-" },
    lookupItemPrefix: { type: String, default: "{0}{1}_{2}" }, //NOTE: {0}: parentRecordPrefix
    systemFieldItemPrefix: { type: String, default: "{0}{1}_{2}" }, //NOTE: {0}: parentRecordPrefix
    fieldItemPrefix: { type: String, default: "{0}{1}" }, //NOTE: {0}: parentRecordPrefix
  },
  emits: ["updateFormula"],
  data() {
    return {
      fieldQuery: "",
      functionQuery: "",
      operatorQuery: "",
      datetimeFunctions: [
        {
          Name: "Now",
          Method: "Now",
          Description: "",
        },
        {
          Name: "Year",
          Method: "Year",
          Description: "",
        },
        {
          Name: "Today",
          Method: "Today",
          Description: "",
        },
        {
          Name: "AddDays",
          Method: "AddDays()",
          Description: "",
        },
        {
          Name: "AddHours",
          Method: "AddHours()",
          Description: "",
        },
        {
          Name: "DayOfWeek",
          Method: "DayOfWeek",
          Description: "",
        },
        {
          Name: "ToDateTime",
          Method: "ToDateTime()",
          Description: "",
        },
        {
          Name: "GetTimeDiff",
          Method: "GetTimeDiff()",
          Description: "",
        },
      ],
      logicalFunctions: [],
      customFunctions: [
        {
          Name: "CURRENT_USER_CULTURE",
          Method: "CURRENT_USER_CULTURE",
          Description: "",
        },
        {
          Name: "PAGELAYOUTNAME",
          Method: "PAGELAYOUTNAME",
          Description: "",
        },
        {
          Name: "REQUEST_FROM",
          Method: "REQUEST_FROM",
          Description: "",
        },
        {
          Name: 'CurrentUserGroup("ParentID")',
          Method: 'CurrentUserGroup("ParentID")',
          Description: "",
        },
        {
          Name: 'CurrentPermissionGroup("ParentID")',
          Method: 'CurrentPermissionGroup("ParentID")',
          Description: "",
        },
        {
          Name: 'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"SearchValue";"ReturnFieldFormulaName")',
          Method:
            'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"SearchValue";"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";SearchFormulaName;"ReturnFieldFormulaName")',
          Method:
            'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";SearchFormulaName;"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName")',
          Method:
            'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName";"OrderByColumn";"OrderType")',
          Method:
            'GetFieldValue("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName";"OrderByColumn";"OrderType")',
          Description:
            "Operators: '=', '<', '<=', '>', '>=', 'contains', 'includes'; Order Types: 'asc', 'desc'",
        },
        {
          Name: 'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"SearchValue";"ReturnFieldFormulaName")',
          Method:
            'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"SearchValue";"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";SearchFormulaName;"ReturnFieldFormulaName")',
          Method:
            'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";SearchFormulaName;"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName")',
          Method:
            'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName")',
          Description: "",
        },
        {
          Name: 'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName";"OrderByColumn";"OrderType")',
          Method:
            'VARIABLE_YOURVARIABLENAME("LookupObjectKey";"LookupFieldFormulaName";"Operator";"SearchValue";"ReturnFieldFormulaName";"OrderByColumn";"OrderType")',
          Description:
            "Operators: '=', '<', '<=', '>', '>=', 'contains', 'includes'; Order Types: 'asc', 'desc'",
        },
        {
          Name: "RandomString(number)",
          Method: "RandomString(number)",
          Description: "",
        },
        {
          Name: "ToDouble",
          Method: "ToDouble()",
          Description: "",
        },
        {
          Name: "Max",
          Method: "Max()",
          Description: "",
        },
        {
          Name: "Min",
          Method: "Min()",
          Description: "",
        },
        {
          Name: "Any",
          Method: "Any()",
          Description: "",
        },
        {
          Name: "NotAny",
          Method: "NotAny()",
          Description: "",
        },
        {
          Name: "LeftValue",
          Method: "LeftValue()",
          Description: "",
        },
        {
          Name: "RightValue",
          Method: "RightValue()",
          Description: "",
        },
        {
          Name: "NumberToWords",
          Method: "NumberToWords()",
          Description: "",
        },
        {
          Name: "GetWorkingDays",
          Method: "GetWorkingDays()",
          Description: "",
        },
        {
          Name: "GetWorkingDate",
          Method: "GetWorkingDate()",
          Description: "",
        },
        {
          Name: "ConvertCurrencyWithType",
          Method: "ConvertCurrencyWithType()",
          Description: "",
        },
        {
          Name: "Count",
          Method: "Count",
          Description: "",
        },
      ],
      mathFunctions: [
        {
          Name: "Abs",
          Method: "Abs()",
          Description: "",
        },
        {
          Name: "Floor",
          Method: "Floor()",
          Description: "",
        },
        {
          Name: "Sqrt",
          Method: "Sqrt()",
          Description: "",
        },
      ],
      stringFunctions: [
        {
          Name: "Substring",
          Method: "Substring()",
          Description: "",
        },
        {
          Name: "ToLower",
          Method: "ToLower()",
          Description: "",
        },
        {
          Name: "ToUpper",
          Method: "ToUpper()",
          Description: "",
        },
        {
          Name: "StartsWith",
          Method: "StartsWith()",
          Description: "",
        },
        {
          Name: "Replace",
          Method: "Replace()",
          Description: "",
        },
        {
          Name: "GetWeekOfYear",
          Method: "GetWeekOfYear()",
          Description: "",
        },
        {
          Name: "Contains",
          Method: "Contains()",
          Description: "",
        },
        {
          Name: "Concat",
          Method: "Concat()",
          Description: "",
        },
        {
          Name: "Equals",
          Method: "Equals()",
          Description: "",
        },
        {
          Name: "ToString",
          Method: "ToString()",
          Description: "",
        },
        {
          Name: "UpperFirstChars",
          Method: "UpperFirstChars()",
          Description: "",
        },
        {
          Name: "Split",
          Method: "Split()",
          Description: "",
        },
      ],
      spatialFunctions: [
        {
          Name: "GetDistance",
          Method: "GetDistance()",
          Description: "",
        },
      ],
      operators: [
        { Name: "+", Description: "" },
        { Name: "-", Description: "" },
        { Name: "*", Description: "" },
        { Name: "/", Description: "" },
        { Name: "%", Description: "" },
        { Name: "<>", Description: "" },
        { Name: "in", Description: "" },
        { Name: "And", Description: "" },
        { Name: "Or", Description: "" },
      ],
      organizationalUnitUser: [
        "EMAIL",
        "NAME",
        "DEGREE",
        "MOBILE_NUMBER",
        "iDENTiTY_NUMBER",
        "EXTENSiON",
        "DEPARTMENT",
        "SPECIFIC_CODE1",
        "SPECIFIC_CODE2",
        "SPECIFIC_CODE3",
        "LEVEL_1_MANAGER",
        "LEVEL_1_MANAGER_NAME",
        "LEVEL_2_MANAGER",
        "LEVEL_2_MANAGER_NAME",
        "LEVEL_3_MANAGER",
        "LEVEL_3_MANAGER_NAME",
      ],
      organizationalUnitUserGroup: [
        "NAME",
        "DESCRIPTION",
        "SPECIFIC_CODE1",
        "SPECIFIC_CODE2",
        "SPECIFIC_CODE3",
        "PARENT",
        "PARENT_NAME",
        "ADMiN",
        "ADMiN_NAME",
      ],
      customKeywords: ['"true"', '"false"'],
      themes: [
        {
          id: "twilight",
          name: "Twilight",
          bgClassName: "black",
        },
        {
          id: "chrome",
          name: "Chrome",
          bgClassName: "white",
        },
        {
          id: "ambiance",
          name: "Ambiance",
          bgClassName: "ambiance",
        },
        {
          id: "mono_industrial",
          name: "Mono Industrial",
          bgClassName: "mono_industrial",
        },
        {
          id: "idle_fingers",
          name: "Idle Fingers",
          bgClassName: "idle_fingers",
        },
      ],
      theme: Object.readCookie("SxFormulaEditorTheme") || "twilight",
      formula: "",
      formulaEditor: "",
      fields: [],
      fieldFormulas: [],
      orgUnitFormulas: [],
      lookupFormulas: [],
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
    };
  },
  components: {
    VAceEditor,
    draggable,
  },
  watch: {
    formulaField(oldValue, newValue) {
      this.formula = String.isNullOrWhiteSpace(this.formulaField.formula)
        ? ""
        : this.formulaField.formula;

      this.formulaEditor = ace.edit("formulaEditor");
      this.formulaEditor.setValue(this.formula);
      this.formulaEditor.commands.addCommand(
        this.$root.ace.commands.fullScreen
      );
      this.formulaEditor.setFontSize(22);

      beautifier.beautify(this.formulaEditor.session);

      if (
        String.isNullOrWhiteSpace(newValue) ||
        (!String.isNullOrWhiteSpace(newValue) &&
          oldValue.customObjectPublicId !== newValue.customObjectPublicId)
      ) {
        this.autoCompleteItemsAppend([], true);
        this.getFields();
        this.functionsOrOperators();
      }
    },
  },
  methods: {
    searchField() {
      var value = this.fieldQuery.toLowerCase();
      $(".formula-field-button").filter(function () {
        $(this).toggle($(this).text().trim().toLowerCase().indexOf(value) > -1);
      });
    },
    searchFunction() {
      var value = this.functionQuery.toLowerCase();
      $(".formula-function-button").filter(function () {
        $(this).toggle($(this).text().trim().toLowerCase().indexOf(value) > -1);
      });
    },
    searchOperator() {
      var value = this.operatorQuery.toLowerCase();
      $(".formula-operator-button").filter(function () {
        $(this).toggle($(this).text().trim().toLowerCase().indexOf(value) > -1);
      });
    },
    updateFormula() {
      this.$emit("updateFormula", this.formula, this.formulaField);
      $(".btn-formula-editor-close").trigger("click");
    },
    getFields() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-FieldList?customObjectPublicId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.fields = response.data.items;
          this.customObjectRelationFields();
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    customObjectRelationFields() {
      var wordList = [{ Name: "CURRENT_RECORD", Meta: "" }],
        lookupObjectIds = [];

      this.fields.forEach((item) => {
        if (item.fieldType === "OrganizationalUnit") {
          if (item.organizationalUnitName === "User") {
            this.organizationalUnitUser.forEach((v) => {
              var formula = String.format(
                this.systemFieldItemPrefix,
                this.parentRecordPrefix,
                item.formulaName,
                v
              );
              wordList.push({
                Name: formula,
                Meta: item.fieldType,
              });

              var currentRecord = this.orgUnitFormulas.find(
                (f) => f.formulaName == item.formulaName
              );
              if (String.isNullOrWhiteSpace(currentRecord)) {
                this.orgUnitFormulas.push({
                  formulaName: item.formulaName,
                  formulas: [
                    {
                      Name: formula,
                      Meta: item.fieldType,
                    },
                  ],
                });
              } else {
                currentRecord.formulas.push({
                  Name: formula,
                  Meta: item.fieldType,
                });
              }
            });
          } else {
            this.organizationalUnitUserGroup.forEach((v) => {
              var formula = String.format(
                this.systemFieldItemPrefix,
                this.parentRecordPrefix,
                item.formulaName,
                v
              );
              wordList.push({
                Name: formula,
                Meta: item.fieldType,
              });
              var currentRecord = this.orgUnitFormulas.find(
                (f) => f.formulaName == item.formulaName
              );
              if (String.isNullOrWhiteSpace(currentRecord)) {
                this.orgUnitFormulas.push({
                  formulaName: item.formulaName,
                  formulas: [
                    {
                      Name: formula,
                      Meta: item.fieldType,
                    },
                  ],
                });
              } else {
                currentRecord.formulas.push({
                  Name: formula,
                  Meta: item.fieldType,
                });
              }
            });
          }
        } else if (item.fieldType === "Lookup") {
          var currentLookupObject = lookupObjectIds.find(
            (f) => f.publicId === item.lookupObjectPublicId
          );
          if (currentLookupObject != null) {
            currentLookupObject.fields.push({
              publicId: item.publicId,
              formulaName: item.formulaName,
            });
          } else {
            lookupObjectIds.push({
              publicId: item.lookupObjectPublicId,
              fields: [
                { publicId: item.publicId, formulaName: item.formulaName },
              ],
            });
          }
        } else {
          wordList.push({
            Name: String.format(
              this.fieldItemPrefix,
              this.parentRecordPrefix,
              item.formulaName
            ),
            Meta: item.fieldType,
          });
          this.fieldFormulas.push({
            Id: item.publicId,
            Name: String.format(
              this.fieldItemPrefix,
              this.parentRecordPrefix,
              item.formulaName
            ),
            Meta: item.fieldType,
          });

          if (
            item.fieldType == "SelectList" ||
            item.fieldType == "Predefined"
          ) {
            wordList.push({
              Name: String.format(
                this.fieldItemPrefix,
                this.parentRecordPrefix,
                `${item.formulaName}_NAME`
              ),
              Meta: item.fieldType,
            });
            this.fieldFormulas.push({
              Id: item.publicId,
              Name: String.format(
                this.fieldItemPrefix,
                this.parentRecordPrefix,
                `${item.formulaName}_NAME`
              ),
              Meta: item.fieldType,
            });
          }
        }
      });

      this.getLookupFields(lookupObjectIds);
      this.autoCompleteItemsAppend(wordList);
    },
    setTheme(themeName) {
      Object.setCookie("SxFormulaEditorTheme", themeName, 1);
      this.theme = themeName;
    },
    functionsOrOperators() {
      var wordList = [];
      this.datetimeFunctions.forEach((v) => {
        wordList.push({ Name: v.Method, Meta: "DateTime Function" });
      });
      this.customFunctions.forEach((v) => {
        wordList.push({ Name: v.Method, Meta: "Custom Function" });
      });
      this.mathFunctions.forEach((v) => {
        wordList.push({ Name: v.Method, Meta: "Math Function" });
      });
      this.stringFunctions.forEach((v) => {
        wordList.push({ Name: v.Method, Meta: "String Function" });
      });
      this.spatialFunctions.forEach((v) => {
        wordList.push({ Name: v.Method, Meta: "Spatial Function" });
      });
      this.operators.forEach((v) => {
        wordList.push({ Name: v.Name, Meta: "Operator" });
      });
      this.customKeywords.forEach((value) => {
        wordList.push({ Name: value, Meta: "Boolean" });
      });

      this.autoCompleteItemsAppend(wordList);
    },
    autoCompleteItemsAppend(wordList, isDefaultCompleter = false) {
      var langTools = ace.require("ace/ext/language_tools");

      if (isDefaultCompleter) {
        langTools.setCompleters([
          langTools.snippetCompleter,
          langTools.keyWordCompleter,
        ]);
        return;
      }

      var staticWordCompleter = {
        getCompletions: function (editor, session, pos, prefix, callback) {
          callback(
            null,
            wordList.map(function (word) {
              return {
                caption: word.Name,
                value: word.Name,
                meta: word.Meta,
              };
            })
          );
        },
      };

      langTools.addCompleter(staticWordCompleter);
    },
    getLookupFields(lookupObjectIds) {
      var lookupWordList = [];
      lookupObjectIds.forEach((lr) => {
        this.$prodGatewayAxios
          .get(
            String.format(
              "/Lcdp-FieldList?customObjectPublicId={0}",
              lr.publicId
            )
          )
          .then((response) => {
            lr.fields.forEach((lrFieldItem) => {
              response.data.items.forEach((field, i) => {
                if (i == 0) {
                  var parentFormula = String.format(
                    "{0}{1}",
                    this.parentRecordPrefix,
                    lrFieldItem.formulaName
                  );
                  lookupWordList.push({
                    Name: parentFormula,
                    FormulaName: parentFormula,
                    Meta: "Lookup",
                  });
                  lookupWordList.push({
                    Name: `${parentFormula}_NAME`,
                    FormulaName: `${parentFormula}_NAME`,
                    Meta: "Lookup",
                  });
                }
                var formula = String.format(
                  this.lookupItemPrefix,
                  this.parentRecordPrefix,
                  lrFieldItem.formulaName,
                  field.formulaName
                );
                lookupWordList.push({
                  Name: formula,
                  FormulaName: lrFieldItem.formulaName,
                  Meta: field.fieldType,
                });
              });

              if (lookupWordList.length > 0) {
                var firstItemLookupFormulaName = lookupWordList[0].FormulaName;
                this.lookupFormulas.push({
                  formulaName: firstItemLookupFormulaName,
                  formulas: lookupWordList,
                });
              }

              this.autoCompleteItemsAppend(lookupWordList);
              lookupWordList = [];
            });
          })
          .catch(function (error) {
            //alert(error);
          });
      });
    },
  },
};
</script>
